import React, { Component } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import { LuHeart, LuShoppingCart, LuLogIn } from "react-icons/lu";
import { IoSearch } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CartContext } from "../../ReactContext/CartContext.js";
import "./style.css";

class Header extends Component {
  state = {
    menuOpen: false,
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  };

  render() {
    const { menuOpen } = this.state;
    return (
      <CartContext.Consumer>
        {({ cartList }) => {
          const totalItems = cartList.reduce((total, item) => total + item.quantity, 0);
          return (
            <div className="header-container">
              <div className="logo-container">
                <Link to="/">
                  <img
                    src="https://i.im.ge/2024/08/15/fDV4Hc.img9.jpeg"
                    alt="logo"
                    className="logo-image"
                  />
                </Link>
              </div>

             <h1 className="shop-name"> கோவை <br/>அம்மணீஸ்</h1>

              <div className="search-box">
            <h1 className="company1">
              <span className="Kovai" style={{ fontFamily: 'Noto Sans Tamil, sans-serif' }}>கோவை </span> அம்மணீஸ்
            </h1>
          </div>
                  <button className="menu-toggle" onClick={this.toggleMenu}>
          {menuOpen ? <FaTimes className="icon-white" /> : <FaBars className="icon-white"/>}
        </button>

              <div className={`nav-elements-container ${menuOpen ? "open" : ""}`}>
                <div className="nav-elements"></div>
                <div className="value1">
                  <Link to="/createUser">
                    <button className="nav-button">
                      <FaUserCircle className="iconmenu"/>
                    </button>
                  </Link>
                  <Link to="/Favorites">
                    <button className="nav-button">
                      <IoIosLogOut className="iconmenu" />
                    </button>
                  </Link>
                  <Link to="/cart">
                    <button className="nav-button cart-button">
                      <LuShoppingCart className="iconmenu"/>
                      {totalItems > 0 && <span className="cart-count">{totalItems}</span>}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          );
        }}
      </CartContext.Consumer>
    );
  }
}

export default Header;
