import { Component } from "react"
import Header from "../Header"
import Carousel from "../Carousel";
import ExclusiveCollection from "../ExclusiveCollection";
import TopTrends from "../TopTrends";
import Contact from "../Contact";
import AllProducts from "../AllProducts";
import "./style.css";

class Home extends Component {
  render() {
    return (
      <>
      <div className="home-container-main">
       <Header/>
       <Carousel/>
       <ExclusiveCollection/>
       <TopTrends/>
       <Contact/>
       </div>
       </>
    );
  }
}

export default Home;
