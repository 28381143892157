import React, { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartList, setCartList] = useState(() => {
    const storedCartList = localStorage.getItem('cartList');
    return storedCartList ? JSON.parse(storedCartList) : [];
  });

  useEffect(() => {
    localStorage.setItem('cartList', JSON.stringify(cartList));
  }, [cartList]);

  const addCartItem = (item) => {
    setCartList((prevCartList) => [...prevCartList, item]);
  };

  const deleteCartItem = (itemId) => {
    setCartList((prevCartList) => {
      const updatedCartList = [...prevCartList];
     
      const index = updatedCartList.findIndex((item) => item.id === itemId);
      
      if (index !== -1) {
        updatedCartList.splice(index, 1);
      }
      return updatedCartList;
    });
  };
  
  

  return (
    <CartContext.Provider value={{ cartList, addCartItem, deleteCartItem }}>
      {children}
    </CartContext.Provider>
  );
};
