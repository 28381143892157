import React, { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Header';
import Contact from '../Contact';
import Cookies from 'js-cookie';
import { Oval } from 'react-loader-spinner';
import './style.css';

class LoginSection extends Component {
  state = {
    email: '',
    password: '',
    errors: {},
    isLoading: false,
    isLoggedIn: false,
  };

  componentDidMount() {
    const token = Cookies.get('jwtToken');
    if (token) {
      this.setState({ isLoggedIn: true });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateInputs = () => {
    const { email, password } = this.state;
    const errors = {};
    let isValid = true;

    if (!email) {
      errors.email = 'Email is required';
      isValid = false;
    }
    if (!password) {
      errors.password = 'Password is required';
      isValid = false;
    }

    this.setState({ errors });
    return isValid;
  };

  submitValues = async (event) => {
    event.preventDefault();

    if (!this.validateInputs()) {
      return;
    }

    this.setState({ isLoading: true });

    const { email, password } = this.state;
    const userData = { email, password };

    try {
      const response = await fetch('https://api-services-clientka.onrender.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();

      if (data.token) {
        Cookies.set('jwtToken', data.token, { expires: 1 });
        this.props.navigate('/');
      } else {
        console.error('Login failed:', data.error);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { errors, isLoading, isLoggedIn } = this.state;

    if (isLoggedIn) {
      return (
        <div>
          <Header />
          <div className="login-container">
            <h1 className="heading-login">You're already logged in!</h1>
            <p className="logged-in-message">You are currently logged in. If you want to log in with a different account, please log out first.</p>
          </div>
          <Contact />
        </div>
      );
    }

    return (
      <div>
        <Header />
        <div className="login-container">
          <h1 className="heading-login">Login to Your Account</h1>
          <form onSubmit={this.submitValues}>
            <div className="input-container">
              <input
                className={`input ${errors.email ? 'input-error' : ''}`}
                type="email"
                placeholder="Email"
                name="email"
                onChange={this.handleChange}
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
            <div className="input-container">
              <input
                className={`input ${errors.password ? 'input-error' : ''}`}
                type="password"
                placeholder="Password"
                name="password"
                onChange={this.handleChange}
              />
              {errors.password && <p className="error-message">{errors.password}</p>}
            </div>
            <button className="submitbutton" type="submit" disabled={isLoading}>
              {isLoading ? (
                <Oval
                  height={20}
                  width={20}
                  color="white"
                  secondaryColor="white"
                  strokeWidth={6}
                  strokeWidthSecondary={6}
                />
              ) : (
                'Login'
              )}
            </button>
            <h2>
              Don't have an account?{' '}
              <Link to="/createUser" className="login">
                <span>Sign Up</span>
              </Link>
            </h2>
          </form>
        </div>
        <Contact />
      </div>
    );
  }
}

function LoginSectionWithRouter(props) {
  const navigate = useNavigate();
  return <LoginSection {...props} navigate={navigate} />;
}

export default LoginSectionWithRouter;
