import { Component } from "react";
import "./style.css"


class TopTrends extends Component {
    render(){
        return (

            <div className = "trends-container">

                <div className="main-container12">
                   <div className = "heading-container">
                    <h1 className = "content-heading"> Every event deserves a story.<span className ="spanElement"> Let's create it together. </span></h1>
                   </div>
                   <div className = "image-container">
                    <img src = "https://www.kumaransilksonline.com/cdn/shop/files/One_of_a_kind_Edited.jpg" alt = "image" className = "silk-image"/>
                   </div>
               </div>
               <div className="main-container12">
                   <div className = "image-container">
                    <img src = "https://www.kumaransilksonline.com/cdn/shop/files/Custom_Designed_2_Altered_b7a555f5-99a2-46b9-b7ad-22a8cef6449f.jpg" alt = "image" className = "silk-image" />
                   </div>
                   <div className = "heading-container">
                    <h1 className = "content-heading">Born in the creative crucible of <span className = "spanElement">Coimbatore </span></h1>
                   </div>
               </div>
            </div>
        )
    }
}

export default TopTrends