import { Component } from "react";
import{Link} from "react-router-dom"
import "./style.css";

class Contact extends Component {
    render() {
        return (
            <div className="contact-container">
                <hr className="line1" />
                <div className="addresscontainer">
            <div className = "image-content-container">
                <img src = "https://i.im.ge/2024/08/15/fDV4Hc.img9.jpeg" className="logo-image1"/>
                <h1 className="company">Kovai Ammani’s </h1>
            </div>

                <div className="content45">
                <p className = "pro">Changing styles becomes more extraordinary for everyone, especially among those who like to be stylish. Hundreds of stocks we provide for all of your stylish desires.</p>
               </div> 
               </div>
             
                    <div className="footer">
                        <div className="footer-links">
                            <a className="anchor" href="#featured">Featured</a>
                      <Link className="link" to = "/allProducts">    <a className="anchor">Shop</a> </Link>
                       <Link className="link" to = "/about">     <a className = "anchor" >About Us</a> </Link>
                            <a className = "anchor" href="#contact">Contact Us</a>
                        </div>
                        </div>
                        <hr className="line2" />
                        <div className="footer-info">
                            <p className = "footer12">&copy; 2024 CopyRight</p>
                            <p className = "footer12"><a className = "footer12" href="#terms">Terms & Conditions</a></p>
                            <p className = "footer12"><a className = "footer12" href="#shipping">Shipping Policy</a></p>
                        </div>
                    </div>
      
        );
    }
}

export default Contact;
