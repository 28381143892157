import { Component } from "react";
import {Link} from "react-router-dom"
import "./style.css";

class FilterPage extends Component {
  render() {
    return (
        <div className = "main-container-1">
      <div className="filer-container">
        <div className="sections-container">
          <img
            src="https://assets.ajio.com/medias/sys_master/root/20220607/dbzJ/629e72d6aeb26921af130a29/-473Wx593H-464450680-peach-MODEL.jpg"
            className="section-image"
            alt="Traditional Sarees"
          />
          <div className="section-content">
            <h1>Traditional Sarees</h1>
          <Link to = "/SareeSection">   <button className="shop-now">Shop Now</button> </Link>
          </div>
        </div>

        <div className="sections-container">
          <img
            src="https://5.imimg.com/data5/GV/XB/WK/SELLER-65335336/kids-dresses-500x500.jpg"
            className="section-image"
            alt="Designer Lehengas"
          />
          <div className="section-content">
            <h1>Kids Wears</h1>
       <Link to = "/KidsSection">  <button className="shop-now">Shop Now</button> </Link>
          </div>
        </div>

        <div className="sections-container">
          <img
            src="https://cdn.rajwadi.com/image/cache/data/designer-patola-print-anarkali-suit-35928-800x1100.jpg"
            className="section-image"
            alt="Anarkali Suits"
          />
          <div className="section-content">
            <h1>Anarkali Suits</h1>
            <Link to = "/AnarkaliSection"> <button className="shop-now">Shop Now</button></Link>
          </div>
        </div>

        <div className="sections-container">
          <img
            src="https://5.imimg.com/data5/SELLER/Default/2022/12/HT/WX/BS/9664580/1-1000x1000.jpg"
            className="section-image"
            alt="Stylish Kurtis"
          />
          <div className="section-content">
            <h1>Stylish Kurtis</h1>
         <Link to = "/KuritsSection">   <button className="shop-now">Shop Now</button> </Link>
          </div>
        </div>

        <div className="sections-container">
          <img
            src="https://5.imimg.com/data5/IOS/Default/2023/2/SN/JB/WY/9664580/product-jpeg-500x500.png"
            className="section-image"
            alt="Elegant Chudithars"
          />
          <div className="section-content">
            <h1>Elegant Chudithars</h1>
         <Link to = "/chuditharSection">   <button className="shop-now">Shop Now</button> </Link>
          </div>
        </div>

        <div className="sections-container">
          <img
            src="https://i.pinimg.com/originals/fa/6c/09/fa6c093ef39678c9c7fa4fc372fedd8c.jpg"
            className="section-image"
            alt="Salwar Kameez"
          />
          <div className="section-content">
            <h1>Salwar Kameez</h1>
        <Link to  = "/salwarSection">   <button className="shop-now">Shop Now</button> </Link> 
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default FilterPage;