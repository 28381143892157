import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Contact from "../Contact";
import "./style.css";
import { FaHeart } from "react-icons/fa"; 

class SareeSection extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
      favorites: JSON.parse(localStorage.getItem('favorites')) || Array(8).fill(false),
    };
  }

  products = [
    {
      name: "Pink Floral Design Saree",
      newPrice: "₹2,499.00",
      oldPrice: "₹3,299.00",
      imgSrc: "https://assets.ajio.com/medias/sys_master/root/20240108/lSXl/659bd5c354c30e62769a3a2d/-473Wx593H-466957562-pink-MODEL.jpg",
    },
    {
      name: "Red Embroidery Saree",
      newPrice: "₹2,799.00",
      oldPrice: "₹3,699.00",
      imgSrc: "https://rukminim2.flixcart.com/image/850/1000/l15bxjk0/sari/g/f/d/free-gt-m-corona2-rama1-the-ethnic-saree-studio-unstitched-original-imagcsymaqu29c6z.jpeg?q=90&crop=false",
    },
    {
      name: "Pink Silk Saree",
      newPrice: "₹3,299.00",
      oldPrice: "₹4,299.00",
      imgSrc: "https://www.jiomart.com/images/product/original/rvambuws5c/bhakarwadi-pink-mysore-silk-ethnic-saree-with-blouse-piece_-gor_ruc-445_-pink-product-images-rvambuws5c-0-202212141705.jpg?im=Resize=(500,630)",
    },
    {
      name: "Red Georgette Saree",
      newPrice: "₹1,999.00",
      oldPrice: "₹2,599.00",
      imgSrc: "https://assets.ajio.com/medias/sys_master/root/20230623/Z9zI/6495270e42f9e729d78b5f2a/-473Wx593H-464595089-purple-MODEL.jpg",
    },
    {
      name: "Red Embellished Saree",
      newPrice: "₹2,199.00",
      oldPrice: "₹3,099.00",
      imgSrc: "https://assets0.mirraw.com/images/10046131/image_zoom.jpeg?1647090391",
    },
    {
      name: "Green Velvet Saree",
      newPrice: "₹2,749.00",
      oldPrice: "₹3,699.00",
      imgSrc: "https://medias.utsavfashion.com/media/catalog/product/cache/1/image/500x/040ec09b1e35df139433887a97daa66f/w/o/woven-art-bangalore-silk-saree-in-teal-blue-v1-snga4577.jpg",
    },
    {
      name: "Green Satin Saree",
      newPrice: "₹2,499.00",
      oldPrice: "₹3,299.00",
      imgSrc: "https://assets.ajio.com/medias/sys_master/root/20230628/smSc/649c1c1aa9b42d15c9140091/-473Wx593H-465799334-aqua-MODEL.jpg",
    },
    {
      name: "Green Floral Design Saree",
      newPrice: "₹1,699.00",
      oldPrice: "₹2,199.00",
      imgSrc: "https://medias.utsavfashion.com/media/catalog/product/cache/1/image/500x/040ec09b1e35df139433887a97daa66f/w/o/woven-art-silk-saree-in-sea-green-v1-szda301.jpg",
    },
  ];

  toggleFavorite = (index) => {
    const newFavorites = [...this.state.favorites];
    newFavorites[index] = !newFavorites[index];
    this.setState({ favorites: newFavorites }, () => {
      localStorage.setItem('favorites', JSON.stringify(newFavorites));
    });
  };

  render() {
    return (
      <>
        <Header />
        <div className="exclusive-container">
          <div className="content-container">
            <h1 className="heading">Unveil the New Era of Elegance</h1>
            <p className="content1">Dress to impress, wherever life takes you</p>
          </div>
          <div className="trending-products-container">
            {this.products.map((product, index) => (
              <div className="eachproduct-1" key={index}>
                <div className="trendingproduct">
                  <Link
                    to={`/detailedSection/${index}`}
                    state={{ product }} 
                  >
                    <img src={product.imgSrc} alt={product.name} className = "detailedSectionImage" />
                  </Link>
                  <FaHeart
                    className="favorite-icon"
                    color={this.state.favorites[index] ? "pink" : "white"}
                    onClick={() => this.toggleFavorite(index)}
                  />
                </div>
                <h4 className="name">{product.name}</h4>
                <span className="newprice">{product.newPrice}</span>
                <span className="oldprice">{product.oldPrice}</span>
              </div>
            ))}
          </div>
          <button className="viewall-button">View All</button>
        </div>
        <Contact />
      </>
    );
  }
}

export default SareeSection;
