import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay'; 
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import { Link } from "react-router-dom";
import './style.css';

const images = [
  {
    url: "https://img.freepik.com/free-photo/studio-portrait-young-woman-wearing-traditional-sari-garment_23-2149565115.jpg",
    title: "Traditional Saree",
    buttonText: "View More",
  },
  {
    url: "https://img.freepik.com/free-photo/young-woman-beautiful-red-dress_1303-17503.jpg",
    title: "Modern Wears",
    buttonText: "Shop Now",
  },
  {
    url: "https://img.freepik.com/free-photo/portrait-beautiful-woman-wearing-traditional-sari-garment_23-2149565112.jpg",
    title: "Silk Saree",
    buttonText: "Explore",
  },
  {
    url: "https://img.freepik.com/free-photo/young-attractive-indian-woman-traditional-dress-woman-dancing-against-white-background_1157-48166.jpg",
    title: "Chiduthars",
    buttonText: "Discover",
  },
  {
    url: "https://img.freepik.com/free-photo/front-view-woman-green-house_23-2148261258.jpg",
    title: "Sundresses",
    buttonText: "Find More",
  }
];

function Carousel() {
  return (
    <div className="main-coursel-container">
      <h1 className="heading-coursel">Featured <span className="span-element">Collections</span></h1>
      <div className="main-container-swiper">
        <Swiper
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={3}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 300,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={{ el: '.swiper-pagination', clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          className="swiper-container"
        >
          {images.map((img, index) => (
            <SwiperSlide key={index}>
              <div className="slide-content">
                <img
                  src={img.url}
                  alt={`Slide ${index + 1}`}
                  className="image-width hover-effect"
                  style={{ cursor: 'pointer' }}
                />
                <div className="slide-overlay">
                  <h2 className="title" style={{ color: '#ffffff', fontSize: "15px" }}>
                    {img.title}
                  </h2>
                  <Link to="/allproducts">
                    <button className="button-color">
                      {img.buttonText}
                    </button>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination"></div>
        </Swiper>
      </div>
    </div>
  );
}

export default Carousel;
