import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode"; 
import Header from "../Header";
import Contact from "../Contact";
import "./style.css";

const CreateUserSection = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("jwtToken"); 
    if (token) {
      const decodedToken = jwtDecode(token);
       console.log(decodedToken) 
      setName(decodedToken.name);
      setEmail(decodedToken.email);
      setPhoneNumber(decodedToken.phoneNumber);
      setSuccess(true);
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      default:
        break;
    }
  };

  const validateInputs = () => {
    const newErrors = {};
    let isValid = true;

    if (!name) {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (!email) {
      newErrors.email = "Email is required";
      isValid = false;
    }
    if (!password) {
      newErrors.password = "Password is required";
      isValid = false;
    }
    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
      isValid = false;
    } else if (phoneNumber.length !== 10) {
      newErrors.phoneNumber = "Phone number must be 10 digits";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const submitValues = (event) => {
    event.preventDefault();

    if (validateInputs()) {
      setIsLoading(true);

      fetch('https://api-services-clientka.onrender.com/api/userAccount', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          password,
          phone: phoneNumber,
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.error && data.error.includes('E11000')) {
            console.error('This email is already registered.');
            setErrors({ email: 'This email is already registered.' });
          } else {
            Cookies.set("jwtToken", data.token);
            setSuccess(true);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="login-container-1">
      <Header />
      <div className="bg-login-container">
        <div className="login-main-container">
          {!success ? (
            <>
              <h1>Create An Account</h1>
              <form onSubmit={submitValues}>
                <div className="input-container">
                  <input
                    className={`input ${errors.name ? "input-error" : ""}`}
                    type="text"
                    placeholder="UserName"
                    name="name"
                    value={name}
                    onChange={handleChange}
                  />
                  {errors.name && <p className="error-message">{errors.name}</p>}
                </div>
                <div className="input-container">
                  <input
                    className={`input ${errors.email ? "input-error" : ""}`}
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                  {errors.email && <p className="error-message">{errors.email}</p>}
                </div>
                <div className="input-container">
                  <input
                    className={`input ${errors.password ? "input-error" : ""}`}
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={handleChange}
                  />
                  {errors.password && <p className="error-message">{errors.password}</p>}
                </div>
                <div className="input-container">
                  <input
                    className={`input ${errors.phoneNumber ? "input-error" : ""}`}
                    type="tel"
                    placeholder="PhoneNumber"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={handleChange}
                    maxLength="10"
                  />
                  {errors.phoneNumber && <p className="error-message">{errors.phoneNumber}</p>}
                </div>
                <button className="submitbutton" type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <Oval
                      height={20}
                      width={20}
                      color="white"
                      secondaryColor="rgba(139, 3, 139, 0.5)"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    'Create an Account'
                  )}
                </button>
                {success && <p className="SuccessMessage">Successfully Created</p>}
                <h2>
                  Already have an account?{" "}
                  <Link to="/login" className="login">
                    <span>Login</span>
                  </Link>
                </h2>
              </form>
            </>
          ) : (
            <div className="profile-container">
              <h4>Welcome, {name}!</h4>
              <img
                src="https://img.freepik.com/premium-vector/blue-circle-with-white-user-vector_941526-7152.jpg?size=626&ext=jpg" 
                alt="Profile"
                className="profile-image"
              />
              <button
                className="submitbutton"
                onClick={() => navigate("/")}
              >
                Go to Home
              </button>
            </div>
          )}
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default CreateUserSection;
