import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Cookies from "js-cookie";
import "./style.css";

class FavoriteSection extends Component {
  handleLogout = () => {
    Cookies.remove('jwtToken');
    window.location.href = "/home";
  };

  render() {
    return (
      <>
        <Header />
        <div className="favorites-container">
          <div className="empty-content">
            <img
              src="https://img.freepik.com/free-vector/mobile-feed-concept-illustration_114360-4799.jpg"
              alt="no-likes"
              className="empty-image"
            />
            <div className="no-favorites-container">
            <p className="login-again-message">
                To continue enjoying our exclusive collections and stay updated with the latest arrivals, please log in again. Your access to personalized recommendations and special offers awaits you!
              </p>
             <div>
              <button className="logout-button" onClick={this.handleLogout}>
                Logout
              </button>
              </div>
              <div className="shopnowbutton">
              <Link to="/home">
                <button className="shop-now-button">ShopNow</button>
              </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FavoriteSection;
