import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faHome, faCreditCard, faCalendarAlt, faLock } from '@fortawesome/free-solid-svg-icons';
import Header from '../Header';
import Contact from '../Contact';
import './style.css';

const Payment = () => {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    address: '',
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Payment Successful!');
    navigate('/'); 
  };

  return (
    <>
      <Header />
      <div className="payment-container">
        <h2>Payment Information</h2>
        <form className="payment-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">
              <FontAwesomeIcon icon={faUser} /> Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={userData.name}
              onChange={handleChange}
          
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">
              <FontAwesomeIcon icon={faEnvelope} /> Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
        
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">
              <FontAwesomeIcon icon={faHome} /> Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={userData.address}
              onChange={handleChange}
       
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="cardNumber">
              <FontAwesomeIcon icon={faCreditCard} /> Card Number
            </label>
            <input
              type="text"
              id="cardNumber"
              name="cardNumber"
              value={userData.cardNumber}
              onChange={handleChange}
          
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="expiryDate">
              <FontAwesomeIcon icon={faCalendarAlt} /> Expiry Date
            </label>
            <input
              type="text"
              id="expiryDate"
              name="expiryDate"
              value={userData.expiryDate}
              onChange={handleChange}
      
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="cvv">
              <FontAwesomeIcon icon={faLock} /> CVV
            </label>
            <input
              type="text"
              id="cvv"
              name="cvv"
              value={userData.cvv}
              onChange={handleChange}
      
              required
            />
          </div>
          <button type="submit" className="submit-button">
            Submit Payment
          </button>
        </form>
      </div>
      <Contact />
    </>
  );
};

export default Payment;
