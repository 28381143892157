import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import Contact from "../Contact";
import { CartContext } from "../../ReactContext/CartContext";
import { FaShoppingCart } from 'react-icons/fa'; 
import "./style.css";

const DetailedViewSection = () => {
  const location = useLocation();
  const { product } = location.state || {}; 
  const { addCartItem } = useContext(CartContext);

  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState(product?.sizes ? product.sizes[0] : ""); 
  const [selectedImage, setSelectedImage] = useState(product?.imgSrc || ""); 

  const courselImagesList = product?.courselImages || []; 

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decrementQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  if (!product) {
    return <p>No product data available</p>;
  }

  const { name, newPrice, oldPrice, description = "No description available", sizes = [] } = product;

  const onClickAddToCart = () => {
    addCartItem({ ...product, quantity, selectedSize });
  };

  return (
    <>
      <Header />
      <div className="detailed-view-container">
        <div className="image-gallery-section">
          <div className="main-image-container">
            <img src={selectedImage || product.imgSrc} alt={name} className="main-image" />
          </div>
          <div className="thumbnail-container">
            {courselImagesList.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index}`}
                className={`thumbnail-image ${selectedImage === image ? 'active' : ''}`}
                onClick={() => setSelectedImage(image)}
              />
            ))}
          </div>
        </div>
        
        <div className="product-details-section">
          <h2 className="product-title">{name}</h2>
          <p className="new-price">Price: {newPrice}</p>
          <p className="product-description">A straight cut kurti is known for its simple and elegant design, featuring a straight, narrow fit that falls straight from the shoulders. It's often worn as a casual or semi-formal outfit and can be styled in various ways to suit different occasions</p>
          <p className="product-description">Neck Type: {product.neckType || "No neck type available"}</p>
          <p className="product-description">Fabric: {product.fabric || "No fabric information available"}</p>
           <h3> Select Size</h3>
          <div className="size-selection">
            <br/>
            {sizes.length > 0 && sizes.map((size, index) => (
              <button
                key={index}
                className={`size-button ${selectedSize === size ? 'active' : ''}`}
                onClick={() => setSelectedSize(size)}
              >
                {size}
              </button>
            ))}
          </div>
          <h3> Quantity</h3>
          <div className="quantity-section">
            <button onClick={decrementQuantity} className="quantity-button">-</button>
            <span className="quantity">{quantity}</span>
            <button onClick={incrementQuantity} className="quantity-button">+</button>
          </div>

          <button className="add-to-cart-button" onClick={onClickAddToCart}>
            <FaShoppingCart className="cart-icon" />
            ADD TO CART
          </button>
        </div>
      </div>
      <Contact />
    </>
  );
};

export default DetailedViewSection;
