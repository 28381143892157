import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CartProvider } from './ReactContext/CartContext'; 
import Home from './Components/Home';
import AllProducts from './Components/AllProducts';
import AboutSection from './Components/Aboutsection';
import CreateUserSection from './Components/CreateUserSection';
import LoginSection from './Components/LoginSection';
import FavoriteSection from './Components/FavoritesSection';
import ChuditharSection from './Components/ChuditharsSection';
import SareeSection from './Components/SareeSection';
import AnarkaliSection from './Components/AnarkaliSection';
import KidsSection from './Components/KidsSection';
import Cart from './Components/Cart';
import KurtisSection from './Components/KurtisSection';
import SalwarSection from './Components/salwarSection';
import DetailedViewSection from './Components/DetailedViewSection';
import Payment from './Components/Payment';
import './App.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <CartProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginSection />} />
            <Route path="/createUser" element={<CreateUserSection />} />
            <Route path="/home" element={<Home />} />
            <Route path="/allProducts" element={<AllProducts />} />
            <Route path="/Favorites" element={<FavoriteSection />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/sareeSection" element={<SareeSection />} />
            <Route path="/chuditharSection" element={<ChuditharSection />} />
            <Route path="/AnarkaliSection" element={<AnarkaliSection />} />
            <Route path="/KidsSection" element={<KidsSection />} />
            <Route path="/KuritsSection" element={<KurtisSection />} />
            <Route path="/detailedSection/:id" element={<DetailedViewSection />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/salwarSection" element={<SalwarSection />} />
            <Route path="/about" element={<AboutSection />} />
          </Routes>
        </CartProvider>
      </BrowserRouter>
    );
  }
}

export default App;
