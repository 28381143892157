import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import "./style.css";
import { FaHeart } from 'react-icons/fa';
import img11 from  "../../JULY 24/KA1123_2.jpg"
import img12 from "../../JULY 24/KA1123_3.jpg"
import img13 from "../../JULY 24/KA1123_4.jpg"
import img21 from "../../JULY 24/KA1125-B2.jpg" 
import img22 from "../../JULY 24/KA1125-B3.jpg"
import img23 from "../../JULY 24/KA1125-B4.jpg"
import img31 from "../../JULY 24/KA1125-C2.jpg"
import img32 from "../../JULY 24/KA1125-C3.jpg"
import img33 from "../../JULY 24/KA1125-C4.jpg"
import img41 from "../../JULY 24/KA1125_2.jpg"
import img42 from "../../JULY 24/KA1125_3.jpg"
import img43 from "../../JULY 24/KA1125_4.jpg"
import img51 from "../../JULY 24/KA1099_2.jpg"
import img52 from "../../JULY 24/KA1099_3.jpg"
import img53 from "../../JULY 24/KA1099_4.jpg"
import img61 from "../../JULY 24/KA1102_2.jpg"
import img62 from "../../JULY 24/KA1102_3.jpg"
import img63 from "../../JULY 24/KA1102_4.jpg"
import img71 from "../../JULY 24/ka1103-2.jpg"
import img72 from "../../JULY 24/ka1103-3.jpg"
import img73 from "../../JULY 24/ka1103-4.jpg"
import img81 from "../../JULY 24/KA1086_2.jpg"
import img82 from "../../JULY 24/KA1086_3.jpg"
import img83 from "../../JULY 24/KA1086_4.jpg"
import img91 from "../../JULY 24/KA1094_2.jpg"
import img92 from "../../JULY 24/KA1094_3.jpg"
import img93 from "../../JULY 24/KA1094_4.jpg"
import img101 from "../../JULY 24/KA1083-4.jpg"
import img102 from "../../JULY 24/KA1083_2.jpg"
import img103 from "../../JULY 24/KA1083_3.jpg"
import img111 from "../../JULY 24/KA1081_2.jpg"
import img112 from "../../JULY 24/KA1081_3.jpg"
import img113 from "../../JULY 24/KA1081_4.jpg"
import img121 from "../../JULY 24/KA906_2.jpg"
import img122 from "../../JULY 24/KA906_3.jpg"
import img123 from "../../JULY 24/KA906_4.jpg"
import img131 from "../../JULY 24/KA965_2.jpg"
import img132 from "../../JULY 24/KA965_3.jpg"
import img133 from "../../JULY 24/KA965_4.jpg"
import img141 from "../../JULY 24/KA971_2.jpg"
import img142 from "../../JULY 24/KA971_3.jpg"
import img143 from "../../JULY 24/KA971_4.jpg"
import img151 from "../../JULY 24/KA443_2.jpg"
import img152 from "../../JULY 24/KA443_3.jpg"
import img153 from "../../JULY 24/KA443_4.jpg"
import img161 from "../../JULY 24/KA445_2.jpg"
import img162 from "../../JULY 24/KA445_3.jpg"
import img163 from "../../JULY 24/KA445_3.jpg"
import img171 from "../../JULY 24/KA458_2.jpg"
import img172 from "../../JULY 24/KA458_3.jpg"
import img173 from "../../JULY 24/KA458_4.jpg"
import img181 from "../../JULY 24/KA472_2.jpg"
import img182 from "../../JULY 24/KA472_3.jpg"
import img183 from "../../JULY 24/KA472_4.jpg"
import img191 from "../../JULY 24/KA473_2.jpg"
import img192 from "../../JULY 24/KA473_3.jpg"
import img193 from "../../JULY 24/KA473_4.jpg"
import img201 from "../../JULY 24/KA487_2.jpg"
import img202 from "../../JULY 24/KA487_3.jpg"
import img203 from "../../JULY 24/KA487_4.jpg"
class KurtisSection extends Component {
  state = {
    products: [
      {
        id:1,
        name: "Anarkali kurti",
        newPrice: "₹499.00",
        oldPrice: "₹599.00",
        neckType : "Round",
        fabric: "Rayon",
        imgSrc: img12,
        courselImages:[
          img11,
          img12,
          img13
        ],
        sizes: ["M", "L","XL",],
      },
      {
        id:2,
        name: "Straight cut kurti",
        newPrice: "₹299.00",
        oldPrice: "₹399.00",
        neckType : "Round",
        fabric: "Rayon",
        imgSrc: img21,
        courselImages:[
          img21,
          img22,
          img23
       ],
        sizes: ["S","M", "L","XXL"],
      },
       {
    id:3,
    name: "Straight cut kurti",
    newPrice: "₹299.00",
    oldPrice: "₹399.00",
    neckType : "Round",
    fabric: "Rayon",
    imgSrc: img32,
    courselImages:[
     img31,
     img32,
     img33
   ],
    sizes: ['S','M','L',"XL", "XXL"],
  },
  {
    id:4,
    name: "Straight cut kurti",
    newPrice: "₹299.00",
    oldPrice: "₹399.00",
    neckType : "Round",
    fabric: "Rayon",
    imgSrc: img42,
    courselImages:[
       img41,
       img42,
       img43
   ],
    sizes: ["XL",'XLL'],
  },
  {
    id:5,
    name: "Straight cut kurti",
    newPrice: "₹562.00",
    oldPrice: "₹749.00",
    fabric: "Cotton",
    neckType:"Scoop",
    imgSrc:img52,
    courselImages:[
    img51,
    img52,
    img53
   ],
    sizes: ["L","XXL"],
  },
  {
    id:6,
    name: "Straight cut kurti",
    newPrice: "₹562.00",
    oldPrice: "₹749.00",
    fabric: "Cotton",
    neckType:"Round",
    imgSrc: img62,
    courselImages:[
      img61,
      img62,
      img63
   ],
    sizes: ["M", "L", 'XL',"XXL"],
  },
  {
    id:7,
    name: "Straight cut kurti",
    newPrice: "₹562.00",
    oldPrice: "₹749.00",
    fabric:"Cotton",
    neckType:"Scoop",
    imgSrc: img72,
    courselImages:[
      img71,
      img72,
      img73
   ],
    sizes: ["M", 'L',"XL", "XXL"],
  },
  {
    id:8,
    name: "A-Line kurti",
    newPrice: "₹562.00",
    oldPrice: "₹749.00",
    neckType:"V-Neck",
    fabric:"Rayon",
    imgSrc: img72,
    courselImages:[
      img81,
      img82,
      img83
   ],
    sizes: ['S',"M",'L', "XL",'XXL'],
  },
  {
    id:9,
    name: "A-Line kurti",
    newPrice: "₹562.00",
    oldPrice: "₹749.00",
    neckType:"Round",
    fabric:"Cotton",
    imgSrc: img92,
    courselImages:[
      img91,
      img92,
      img93
   ],
    sizes: ['S',"M",'L', "XL", "XXL"],
  },
  {
    id:10,
    name: "Straight cut kurti",
    newPrice: "₹549.00",
    oldPrice: "₹412.00",
    neckType:"Round",
    fabric:"Rayon",
    imgSrc: img102,
    courselImages:[
      img101,
      img102,
      img103
   ],
    sizes: ['S',"M", "XL", "XXL"],
  },  
    {
      id:11,
    name: "Naira cutkurti",
    newPrice: "₹529.00",
    oldPrice: "₹699.00",
    neckType:"Round",
    fabric:"Rayon",
    imgSrc: img112,
    courselImages:[
      img111,
      img112,
      img113
   ],
    sizes: ["L"],
  },
  {
    id:12,
    name: "Straight cut kurti",
    newPrice: "₹480.00",
    oldPrice: "₹640.00",
    neckType:"Collar-Type",
    fabric:"Rayon",
    imgSrc: img122,
    courselImages:[
      img121,
      img122,
      img123
   ],
    sizes: ["M", "XL"],
  },
  {
    id:13,
    name: "Straight cut kurti",
    newPrice: "₹480.00",
    oldPrice: "₹640.00",
    neckType:"Collar-Type",
    fabric:"Cotton",
    imgSrc: img132,
    courselImages:[
      img131,
      img132,
      img133
   ],
    sizes: ["M",'L', "XL", "XXL"],
  },
  {
    id:14,
    name: "Straight cut kurti",
    newPrice: "₹698.00",
    oldPrice: "₹930.00",
    neckType:"Round",
    fabric:"Rayon",
    imgSrc: img142,
    courselImages:[
      img141,
      img142,
      img143
   ],
   sizes: ["M",'L', "XL", "XXL"],
  },
  {
    id:15,
    name: "Straight cut kurti",
    newPrice: "₹599.00",
    oldPrice: "₹799.00",
    neckType:"Round",
    fabric:"Rayon",
    imgSrc: img152,
    courselImages:[
        img151,
        img152,
        img153
   ],
   sizes: ["M",'L', "XL", "XXL"],
  },
  {
    id:16,
    name: "A-Line kurti",
    newPrice: "₹614.00",
    oldPrice: "₹819.00",
    neckType:"Collar-Neck",
    fabric:"Rayon",
    imgSrc: img162,
    courselImages:[
      img161,
      img162,
      img163
   ],
   sizes: ["M",'L', "XL", "XXL"],
  },
   {
    id:17,
    name: "Anarkali kurti",
    newPrice: "₹1,199.00",
    oldPrice: "₹1,599.00",
    neckType:"Scoop",
    fabric:"Silk-Cotton",
    imgSrc: img172,
    courselImages:[
      img171,
      img172,
      img173
   ],
   sizes: ["M",'L', "XL", "XXL"],
  },
  {
    id:18,
    name: "Anarkali kurti",
    newPrice: "₹1,496.00",
    oldPrice: "₹1,995.00",
     neckType:"Round",
     fabric:"Rayon",
    imgSrc: img182,
    courselImages:[
      img181,
      img182,
      img183
   ],
   sizes: ["M",'L', "XL", "XXL"],
  },
  {
    id:19,
    name: "Anarkali kurti",
    newPrice: "₹1,496.00",
    oldPrice: "₹1,995.00",
    neckType:"Round",
     fabric:"Rayon",
    imgSrc: img192,
    courselImages:[
      img191,
      img192,
      img193
   ],
   sizes: ["M",'L', "XL", "XXL"],
  },
  {
    id:20,
    name: "Anarkali kurti",
    newPrice: "₹1,799.00",
    oldPrice: "₹2,398.00",
    neckType:"Round",
     fabric:"Cotton",
    imgSrc: img202,
    courselImages:[
      img201,
      img202,
      img203
   ],
   sizes: ["M",'L', "XL", "XXL"],
  } 
    ],
    likedProducts: JSON.parse(localStorage.getItem('likedProducts')) || {},
  };

  toggleLike = (id) => {
    const { likedProducts } = this.state;
    const newLikedProducts = { ...likedProducts, [id]: !likedProducts[id] };
    this.setState({ likedProducts: newLikedProducts }, () => {
      localStorage.setItem('likedProducts', JSON.stringify(newLikedProducts));
    });
  };

  render() {
    const { products, likedProducts } = this.state;

    return (
      <>
        <Header />
        
        <div className="saree-container">
          <div className="saree-banner-container">
            <img
              src="https://www.jaipurkurti.com/cdn/shop/collections/Kurtas-_-Kurtis-Banner.jpg"
              alt="kurtis-banner-image"
              className="specific-banner-image"
            />
          </div>

          <div className="saree-products-container">
            <div className="trending-products-container">
              {products.map((product) => (
                <div key={product.id} className="eachproduct-1">
                  <div className="trendingproduct">
                  <Link
                    to={`/detailedSection/${product.id}`}
                    state={{ product }} 
                  >
                    <img src={product.imgSrc} alt={product.name} />
                  </Link>

                    <button 
                      className={`like-button ${likedProducts[product.id] ? 'liked' : ''}`} 
                      onClick={() => this.toggleLike(product.id)}
                    >
                      <FaHeart />
                    </button>
                  </div>
                  <h4 className="name">{product.name}</h4>
                  <span className="newprice">{product.newPrice}</span>
                  <span className="oldprice">{product.oldPrice}</span>
                  <div className="product-details">
                    <p className="fabric">Fabric: {product.fabric}</p>
                    <p className="neckType">Neck Type: {product.neckType}</p>
                  </div>
                  <div className="sizes">
                    {product.sizes.map((size, i) => (
                      <span key={i} className="size">
                        {size}
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default KurtisSection;
