import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { CartContext } from '../../ReactContext/CartContext';
import Contact from '../Contact';
import Header from '../Header';
import './style.css';

const Cart = () => {
  const { cartList, deleteCartItem } = useContext(CartContext);
  const navigate = useNavigate();

  const handlePayNow = () => {
    navigate('/payment');
  };

  let totalAmount = 0;

  for (let i = 0; i < cartList.length; i++) {
    const item = cartList[i];
    const price = parseFloat(item.newPrice.replace(/[₹,]/g, '')) || 0;
    const quantity = Number(item.quantity) || 0;
    totalAmount += price * quantity;
  }

  return (
    <>
      <Header />
      <div className="cart-container">
        <h2>My Cart</h2>
        {cartList.length === 0 ? (
          <p className="empty-cart-message">
            <span className="empty-cart-text">Your cart is currently empty.</span>
            <br />
            <span className="encouragement-text">Browse our collections and add items to your cart to start shopping!</span>
          </p>
        ) : (
          <>
            <ul className="cart-list">
              {cartList.map(item => {
                const price = parseFloat(item.newPrice.replace(/[₹,]/g, '')) || 0;
                return (
                  <li key={item.id} className="cart-item">
                    <img className="cart-item-image" src={item.imgSrc || 'default-image.png'} alt={item.name || 'Product Image'} />
                    <div className="cart-item-details">
                      <h3 className="cart-item-title">{item.name || 'Product Name'}</h3>
                      <p className="cart-item-price">Price: ₹{price.toFixed(2)}</p>
                      <p className="cart-item-quantity">Quantity: {item.quantity || '0'}</p>
                      {item.selectedSize && (
                        <p className="cart-item-size">Size: {item.selectedSize}</p>
                      )}
                      <button className="remove-button" onClick={() => deleteCartItem(item.id)}>Remove</button>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="cart-footer">
              <div className="total-amount">
                <h3>Total Amount: ₹{totalAmount.toFixed(2)}</h3>
              </div>
              <button className="pay-now-button" onClick={handlePayNow}>
                Pay Now
              </button>
            </div>
          </>
        )}
      </div>
      <Contact />
    </>
  );
};

export default Cart;
