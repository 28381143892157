import {Component} from "react"
import Header from "../Header"
import Contact from "../Contact"
import "./style.css"


class AboutSection extends Component{
    render(){
        return (
            <>
            <Header/>  
            <div className = "about-section-container">
             <div className = "about-contant-container">
             <p className="aboutcontent"> Welcome to KovaiAmman's, your ultimate destination for exquisite sarees and traditional Indian attire. Since 2012, we have been dedicated to offering a diverse collection of sarees that blend timeless elegance with modern trends. Our curated selection features everything from luxurious silk sarees to stunning georgette and cotton pieces, each crafted with care and attention to detail.
                </p>
                <p className="aboutcontent" > At KovaiAmman's, we believe that a saree is more than just clothing—it’s a celebration of culture, heritage, and personal style. Our passionate team is committed to helping you find the perfect saree for every occasion, whether it’s a grand celebration or a special everyday moment.
                </p>
                <p className="aboutcontent">
                We pride ourselves on exceptional customer service and a warm, welcoming atmosphere. Thank you for choosing us to be a part of your saree journey. We look forward to serving you and making your saree experience unforgettable!
                </p> 
            </div>
            <Contact/>
            </div>
            </>

        )
    }
}

export default AboutSection