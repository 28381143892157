import React, { Component } from "react";
import "./style.css";
import { FaHeart } from "react-icons/fa"; 
import { Link } from "react-router-dom";
import img11 from "../../offer kurti/KA1185_2.jpg"
import img12 from "../../offer kurti/KA1185_3.jpg"
import img13 from "../../offer kurti/KA1185_4.jpg"
import img21 from "../../offer kurti/KA1184_2.jpg"
import img22 from "../../offer kurti/KA1184_3.jpg"
import img23 from "../../offer kurti/KA1184_4.jpg"
import img31 from "../../offer kurti/KA1183_2.jpg"
import img32 from "../../offer kurti/KA1183_3.jpg"
import img33 from "../../offer kurti/KA1183_4.jpg"
import img41 from "../../offer kurti/KA1176_2.jpg"
import img42 from "../../offer kurti/KA1176_3.jpg"
import img43 from "../../offer kurti/KA1176_4.jpg"
import img51 from "../../offer kurti/KA1175_2.jpg"
import img52 from "../../offer kurti/KA1175_3.jpg"
import img53 from "../../offer kurti/KA1175_4.jpg"
import img61 from "../../offer kurti/KA1163_2.jpg"
import img62 from "../../offer kurti/KA1163_3.jpg"
import img63 from "../../offer kurti/KA1163_4.jpg"
import img71 from "../../offer kurti/KA1153_2.jpg"
import img72 from "../../offer kurti/KA1153_3.jpg"
import img73 from "../../offer kurti/KA1153_4.jpg"
import img81 from "../../offer kurti/KA1152_2.jpg"
import img82 from "../../offer kurti/KA1152_4.jpg"
import img83 from "../../offer kurti/KA1152_3.jpg"
import img91 from "../../offer kurti/KA1151(2)_2.jpg"
import img92 from "../../offer kurti/KA1151(2)_3.jpg"
import img93 from "../../offer kurti/KA1151(2).jpg"
import img101 from "../../offer kurti/KA1151(3)_3.jpg"
import img102 from "../../offer kurti/KA1151(3)_4.jpg"
import img103 from "../../offer kurti/KA1151(3)_4.jpg"
import img111 from "../../offer kurti/KA1150_2.jpg"
import img112 from "../../offer kurti/KA1150_3.jpg"
import img113 from "../../offer kurti/KA1150_4.jpg"
import img121 from "../../offer kurti/KA999_2.jpg"
import img122 from "../../offer kurti/KA999_3.jpg"
import img123 from "../../offer kurti/KA999_4.jpg"
import img131 from "../../offer kurti/KA1141_3.jpg"
import img132 from "../../offer kurti/KA1141_2.jpg"
import img133 from "../../offer kurti/KA1141_4.jpg"
import img141 from "../../offer kurti/KA1141(2)_2.jpg"
import img142 from "../../offer kurti/KA1141(2)_3.jpg"
import img143 from "../../offer kurti/KA1141(2)_4.jpg"
import img151 from "../../offer kurti/KA1140(4).jpg"
import img152 from "../../offer kurti/KA1140(4)_2.jpg"
import img153 from "../../offer kurti/KA1140_(2)_4.jpg"
import img161 from "../../offer kurti/KA1140(2)_3.jpg"
import img162 from "../../offer kurti/KA1140(2)_4.jpg"
import img163 from "../../offer kurti/KA1140(3).jpg"
import img171 from "../../offer kurti/KA1140(3)_2.jpg"
import img172 from "../../offer kurti/KA1140(3)_3.jpg"
import img173 from "../../offer kurti/KA1140(3)_4.jpg"







class ExclusiveCollection extends Component {
  state = {
    favorites: Array(8).fill(false),
  };

  products = [
    {
      id:1,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer:"50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img12,
      courselImages:[
        img11,
        img12,
        img13
     ],
      sizes: ["M"],
    },
    {
      id:2,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer:"50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img21,
      courselImages:[
        img21,
        img22,
        img23
     ],
      sizes: ['S',"M", 'L',"XL", "XXL"],
    },
    {
      id:3,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img32,
      courselImages:[
        img31,
        img32,
        img33
     ],
      sizes: ["S","M", 'L',"XL", "XXL"],
    },
    {
      id:4,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img42,
      courselImages:[
        img41,
        img42,
        img43
     ],
      sizes: ["M", 'L',"XL", "XXL"],
    },
    {
      id:5,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img52,
      courselImages:[
        img51,
        img52,
        img53
     ],
      sizes: ["M", 'L',"XL", "XXL"],
    },
    {
      id:6,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img62,
      courselImages:[
        img61,
        img62,
        img63
     ],
      sizes: ["S"],
    },
    {
      id:7,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img72,
      courselImages:[
        img71,
        img72,
        img73
     ],
      sizes: ["S",'L',"XL", "XXL"],
    },
    {
      id:8,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img82,
      courselImages:[
        img81,
        img82,
        img83
     ],
      sizes: ["M", 'L',"XL", "XXL"],
    },

    {
      id:9,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img92,
      courselImages:[
        img91,
        img92,
        img93
     ],
     sizes: ["M", 'L',"XL", "XXL"],
    },
    {
      id:10,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img102,
      courselImages:[
        img101,
        img102,
        img103
     ],
     sizes: ["M", 'L',"XL","XXL"],
    },
    {
      id:11,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img112,
      courselImages:[
        img111,
        img112,
        img113
     ],
     sizes: ['M','L',"XL"],
    },
    {
      id:12,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img122,
      courselImages:[
        img121,
        img122,
        img123
     ],
     sizes: ['XL'],
    },
    {
      id:13,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img132,
      courselImages:[
        img131,
        img132,
        img133
     ],
     sizes: ['L'],
    },
    {
      id:14,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img142,
      courselImages:[
        img141,
        img142,
        img143
     ],
     sizes: ['XL'],
    },
    {
      id:15,
      name: "A-Line kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img152,
      courselImages:[
        img151,
        img152,
        img153
     ],
     sizes: ['XL'],
    },
    {
      id:16,
      name: "Straight cut kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img162,
      courselImages:[
        img161,
        img162,
        img163
     ],
     sizes: ["S","M", 'L',"XL", "XXL"],
    },
    {
      id:17,
      name: "A-Line kurti",
      newPrice: "₹499.00",
      offer : "50%",
      fabric:"Rayon",
      neckType:"Round",
      imgSrc: img172,
      courselImages:[
        img171,
        img172,
        img173
     ],
     sizes: ["XL"],
    },

  ];

  toggleFavorite = (index) => {
    const newFavorites = [...this.state.favorites];
    newFavorites[index] = !newFavorites[index];
    this.setState({ favorites: newFavorites });
  };

  render() {
    return (
      <div className="exclusive-container">
      <div className="content-container">
        <h1 className="heading">Unveil the New Era of Elegance</h1>
        <p className="content1">50% Offers Hurry!!..</p>
      </div>
        <div className="trending-products-container">
          {this.products.map((product, index) => (
            <div className="eachproduct-1" key={index}>
              <div className="trendingproduct">
                <Link
                  to={`/detailedSection/${index}`}
                  state={{ product }} 
                >
                  <img src={product.imgSrc} alt={product.name} />
                </Link>
                <FaHeart
                  className="favorite-icon"
                  color={this.state.favorites[index] ? "pink" : "white"}
                  onClick={() => this.toggleFavorite(index)}
                />
              </div>
              <h4 className="name">{product.name}</h4>
              <span className="newprice">{product.newPrice}</span>
              <span className="oldprice">{product.oldPrice}</span>
              <div className="product-details">
                    <p className="fabric">Fabric: {product.fabric}</p>
                    <p className="neckType">Neck Type: {product.neckType}</p>
                  </div>
                  <div className="sizes">
                    {product.sizes.map((size, i) => (
                      <span key={i} className="size">
                        {size}
                      </span>
                    ))}
                  </div>
            </div>
          ))}
        </div>
     <Link to = "/allproducts">   <button className="viewall-button">View All</button></Link>
      </div>
    );
  }
}

export default ExclusiveCollection;