import { Component } from "react";
import Header from "../Header";
import Contact from "../Contact";
import "./style.css";

class SalwarSection extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="no-products-container">
          <h2 className="no-products-heading">No Products Found</h2>
          <img
            src="https://img.freepik.com/free-vector/purchase-ban-online-store-website-error-cancel-buying-order-placing-inability-buy-limit-budget-line-online-buyer-cartoon-character_335657-1173.jpg?ga=GA1.1.2057622067.1700584469&semt=ais_hybrid"
            alt="No Products"
            className="no-products"
          />
        </div>
        <Contact />
      </>
    );
  }
}

export default SalwarSection;
