import React, { Component } from "react";
import Header from "../Header";
import FilterPage from "../FilterPage";
import Contact from "../Contact";
import "./style.css";

class AllProducts extends Component {
  render() {
    return (
<>
<Header />

      <div className="all-products-page">
        <div className="all-products-container">
          <div className="products-container">
          <div className="banner-container">
            <img
              src="https://kalyansilks.com/_next/image?url=https%3A%2F%2Fapi.kalyansilks.com%2Fmedia%2Fvegam%2Fhomepage%2Fimages%2Fbanner.jpg&w=3840&q=75"
              alt="banner-image"
              className="banner-image"
            />
          </div>
          <h1 className="heading"> Our Collections </h1>
          <FilterPage />
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default AllProducts;